<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa lainaa {{ loan.name }}</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <v-card class="mt-2">
      <v-container>
        <p>
          <strong class="mt-5">
            Lainaa muokattu viimeksi
            {{ formatDate(loan.updatedAt) }}
          </strong>
        </p>
        <v-row dense v-if="loan.payments.length > 0">
          <v-col cols="12" md="4">
            <div class="last-payment">
              <strong>
                Viimeisin lyhennys
                {{ formatDate(loan.payments[loan.payments.length - 1].date) }}
              </strong>
              <br />
              Pääoman osuus
              {{ formatCurrency(loan.payments[loan.payments.length - 1].equity) }}
              <br />
              Koron osuus
              {{ formatCurrency(loan.payments[loan.payments.length - 1].interests) }}
            </div>
          </v-col>
        </v-row>
      </v-container>

      <loan-form :loan="loan" submit-btn-text="Tallenna" @sendloan="submit($event)"></loan-form>
    </v-card>
  </div>
</template>

<script>
import LoanForm from "../../components/Loan/LoanForm";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import { mapMutations } from "vuex";

export default {
  title: "Muokkaa lainaa",

  components: {
    LoanForm,
  },

  mixins: [mixins],

  data() {
    return {
      loan: {
        payments: [],
      },
    };
  },

  async created() {
    this.setLoading(true);

    let res = await axiosMethods.get("/api/v1/loan/getOne/" + this.$route.params.id);

    // format dates
    let loan = res.data.loan;

    loan.startDate = new Date(loan.startDate).toISOString().substring(0, 10);
    loan.curRefRateIntStart = new Date(loan.curRefRateIntStart).toISOString().substring(0, 10);
    loan.nextPaymentDate = new Date(loan.nextPaymentDate).toISOString().substring(0, 10);
    this.loan = loan;
  },

  methods: {
    ...mapMutations("loan", ["setLoading"]),

    async submit(loan) {
      try {
        await axiosMethods.update(`/api/v1/loan/edit/${this.$route.params.id}`, loan);

        this.showPopup("Lainan muokkaus onnistui", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped>
.last-payment {
  background: rgb(224, 224, 224);
  padding: 5%;
  border-radius: 4px;
}
</style>
